@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.ContactWrapper {
  .ContactImageWrapper {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    padding-top: calc(33% - 80px);

    .contact-bg-img {
      width: 100%;
    }

    .transparent-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .48);
      padding: $mainPadding;

      .contact-main-title {
        width: $mainContent;
        font-size: 3.5vw;
        font-family: Bold;
        color: white;
        text-transform: uppercase;
        margin: auto;
        margin-bottom: calc(#{$mainPadding} / 2);
      }
    }
  }

  .ContactMainContent {
    padding: $mainPadding 0;
    width: $mainContent;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    .LeftSide, .RightSide {
      width: calc(50% - 30px);

      .subtitle {
        font-family: Bold;
        letter-spacing: 0.4px;
        margin-bottom: 10px;
      }

      .description {
        font-family: Regular;
        color: #666;
        letter-spacing: 0.4px;
        margin-bottom: 12px;
      }
    }

    .LeftSide {
      .contact-items {
        display: flex;

        .item-card {
          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .line {
        width: 100%;
        border-top: 1px solid #d9d9d9;
        margin: 30px 0;
      }

      .social-wrapper {
        width: 90px;
        display: flex;
        justify-content: space-between;

        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .item-card {
        display: flex;
        align-items: center;
        min-width: 200px;

        .icon-wrapper {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background-color: #F5F6F7;
          margin-right: 14px;
          transition: .2s;

          &:hover {
            background-color: $brandColor;
          }

          svg {
            path {
              fill: #202020;
            }
          }
        }

        .item-card-content{
          width: calc(100% - 64px);
        }
        .item-card-content-title {
          font-family: Medium;
          margin-bottom: 2px;
          font-size: 16px;
        }

        .item-card-content-text {
          color: #666;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: Regular;
          font-size: 14px;
        }
      }
    }

    .RightSide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .custom-input-group {
        input {
          margin-bottom: 10px;
        }
      }

      .fields-wrapper {
        margin-top: 15px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .fields-horizontal {
          display: flex;
          justify-content: space-between;

          .custom-input-group {
            width: calc(50% - 5px);

            input {
              margin-bottom: 10px;
            }
          }
        }
      }

      .anticon-loading {
        margin-left: 10px;

        svg {
          path {
            fill: #202020;
          }
        }
      }

      .tick-icon {
        height: 24px;
        width: 24px;
        margin-left: 10px;
        transition: all 0.2s;
        visibility: visible;

        path {
          fill: #202020;
        }
      }

      .show-tick {
        visibility: visible;
        opacity: 1;
      }

      .hide-tick {
        visibility: hidden;
        opacity: 0;
        width: 0;
        margin-left: 0;
      }
    }
  }

  @media all and (max-width: 1100px) {
    .ContactImageWrapper {
      background-size: cover;
      padding-top: 300px;

      .transparent-bg {
        height: 300px;
      }
    }
    .ContactMainContent {
      flex-direction: column;

      .LeftSide, .RightSide {
        width: 100%;

        .subtitle {
          font-size: 3vw;
        }
      }

      .RightSide {
        margin-top: 40px;
      }
    }
  }

  @media all and (max-width: 1024px) {
    .ContactImageWrapper {
      .transparent-bg {
        padding: 80px $mainPadding;
      }
    }
    .ContactMainContent {
      padding: $mainPadding;
    }
  }
  @media all and (max-width: 800px) {
    .ContactImageWrapper {
      padding-top: 250px;

      .transparent-bg {
        height: 250px;

        .contact-main-title {
          font-size: 6vw;
        }
      }
    }
    .ContactMainContent {
      padding-bottom: 50px;
      flex-direction: column;

      .LeftSide, .RightSide {
        .subtitle {
          font-size: 4vw;
        }
      }
    }
  }
  @media all and (max-width: 600px) {
    .ContactImageWrapper {
      padding-top: 200px;

      .transparent-bg {
        height: 200px;

        .contact-main-title {
          font-size: 7vw;
        }
      }
    }
    .ContactMainContent {
      padding-bottom: 50px;
      flex-direction: column;

      .LeftSide, .RightSide {
        .subtitle {
          font-size: 5vw;
        }
      }
      .LeftSide {
        .line {
          margin: 20px 0;
        }
      }
    }
  }

  @media all and (max-width: 430px) {
    .ContactImageWrapper {
      padding-top: 160px;
      .transparent-bg {
        height: 160px;
        .contact-main-title {
          margin-top: 30px;
          font-size: 6.5vw;
        }
      }
    }

    .ContactMainContent {
      flex-direction: column;

      .LeftSide, .RightSide {
        .subtitle {
          font-size: 5.5vw;
        }
      }

      .LeftSide {
        .contact-items {
          flex-direction: column;

          .item-card {
            width: 100%;

            &:first-child {
              padding-bottom: 20px;
              border-bottom: 1px solid #d9d9d9;
              margin-right: 0;
            }
            &:nth-child(2) {
              padding: 20px 0;
            }

            &:last-child {
              border-top: 1px solid #d9d9d9;
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
}
