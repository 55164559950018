@import "../fonts";
@import "../mainVariables";

.footerWrapper {
  background-color: #F3F6FA;

  .top-side {
    display: flex;
    padding: 50px calc((100% - #{$mainContent}) / 2) 60px calc((100% - #{$mainContent}) / 2);
    justify-content: space-between;
    background: #141414;

    .col {
      display: flex;
      flex-direction: column;

      &:first-child{
        margin-right: 80px;
      }
      .logo-wrapper {
        img {
          height: 30px;
          margin-bottom: 44px;
        }

        p {
          margin-bottom: 8px;
        }
      }

      .title {
        color: white;
        font-size: 19px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.03em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-transform: uppercase;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a p, p {
        transition: .2s;
        cursor: pointer;

        &:hover {
          color: $brandColor;
        }
      }
      .address_footer{
        margin-bottom: 10px;
      }
      a{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #999999;
        transition: .2s;
        margin-bottom: 10px;
        &.social{
          margin-bottom: 10px;
        }
        svg{
          margin-right: 6px;
          transition: .2s;
          path{
            fill: white;
          }
        }
        &:hover{
          color:white;
          svg{
            path{
              fill: $brandColor;
            }
          }
        }
      }
      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #999999;
        margin-bottom: unset;
        display: flex;
        align-items: center;
      }

      .social-wrapper {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          cursor: pointer;
          width: 22px;
          height: 22px;
          transition: all 0.2s;
        }

        .fb:hover {
          svg {
            rect {
              fill: #3b5998;
            }

            path {
              fill: white;
            }
          }
        }

        .insta:hover {
          svg {
            rect {
              fill: #fbad50;
            }

            path {
              fill: white;
            }
          }
        }

        .in:hover {
          svg {
            rect {
              fill: #0072b1;
            }

            path {
              fill: white;
            }
          }
        }
      }
    }

    .go-up {
      width: 64px;
      height: 64px;
      background-color: #FADA45;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transform: rotate(180deg);
      cursor: pointer;
      transition: all 0.5s ease 0s;

      &:hover {
        background-color: $brandSecColor;
      }
    }
  }

  .bottom-side {
    padding: 20px calc((100% - #{$mainContent}) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #484848;
    .links_wrapper{
      display: flex;
      align-items: center;
      span{
        font-size: 13px;
        font-weight: 400;
        line-height: 15.51px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFFCC;
      }
      .links{
        margin-left: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFFCC;
      }
    }

    .developed-by {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: white;


      a {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-left: 10px;
        color: white;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .top-side{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0  15% 22px 20px;
      position: relative;
      .col{
        width: 48%;
        margin-top: 36px;
        &:first-child{
          display: none;
        }
      }
      .go-up{
        width: 54px;
        height: 54px;
        position: absolute;
        right: 20px;
        bottom: 22px;
      }
    }
  }

  @media all and (max-width: 800px) {

    .bottom-side {
      padding: 20px $mainPadding 100px $mainPadding;
      font-size: 12px;
      //flex-direction: column;
      align-items: flex-end;
      .links_wrapper{
        flex-direction: column;
        align-items: flex-start;
        .links{
          margin-left: unset;
          margin-top: 10px;
        }
      }

      .developed-by {
        margin-top: 10px;
      }
    }
  }
  @media all and (max-width: 450px) {

    .bottom-side {
      flex-direction: column;
      align-items: flex-start;
      .links_wrapper{
        flex-direction: column;
        align-items: flex-start;
        .links{
          margin-left: unset;
          margin-top: 10px;
        }
      }

      .developed-by {
        margin-top: 10px;
      }
    }
  }

}

.languages-wrapper {
  width: 168px;

  .ant-dropdown-menu {
    padding: 15px 0 !important;
    box-shadow: 0px 2px 8px 0px #0000001F;
  }

  .language-dropdown-item {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 14px;

    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      height: 40px;

      div {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #222222;
      }

      img {
        width: 20px;
        height: 14px;
        margin-right: 12px;
      }
    }
  }
}

.dropdown-menu-item {
  text-transform: uppercase;
  font-size: 14px;
  font-family: Medium;
  letter-spacing: 1px;
  color: white;

  div {
    position: relative;
    top: 1px;
  }

  img {
    width: 24px;
    height: 20px;
    margin-right: 10px;
    border-radius: 1px;
  }

  svg {
    margin-top: -2px;

    path {
      fill: white;
    }
  }
}
