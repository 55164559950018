@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.advertisement-modal {
  width: 450px !important;

  .ant-modal-content {
    border-radius: 16px;

    .ant-modal-body {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      .content {
        width: 100%;
        height: 100%;

        .offer-img {
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 300/160;
          object-fit: cover;
        }

        .offer-title {
          width: 100%;
          font-weight: 700;
          margin-top: 10px;
          font-size: 24px;
          line-height: 30px
        }

        .offer-description {
          white-space: pre-line;
          margin-top: 10px;
          width: 100%;
          font-size: 16px;
          line-height: 25px;
          max-height: 150px;
          overflow-y: auto;
        }

        .modal-actions {
          margin-top: 30px;
          display: flex;
          width: 100%;
          flex-direction: column;

          .custom-input-group {
            background-color: #f5f5f8;
            border: 1px solid #f5f5f8;
            margin-bottom: 16px;
            height: 50px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-radius: 8px;

            &.invalid {
              border-color: #dc3545;
            }

            .custom-label {
              margin-bottom: 0;
              transform: translateY(0);
            }

            input {
              border-bottom: none;
              padding: 10px 0 0 0;
            }

            &.active {
              .custom-label {
                font-size: 11px;
                color: #00000052;
                transform: translateY(-15px);
              }
            }
          }

          .offer-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2e2e2e;
            height: 50px;
            font-size: 16px;
            color: white;
            border-radius: 8px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.01em;
            white-space: nowrap;


            &:hover {
              background-color: #494848;
            }

            &.bg-green {
              background-color: #5cb85c; //31b720
            }
          }
        }
      }
    }

    .ant-modal-close {
      right: -32px;
      top: -4px;

      .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        line-height: unset;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: white;
          }
        }

        &:hover {
          svg {
            path {
              fill: #d4d4d4;
            }
          }
        }
      }
    }


    .ant-modal-footer {
      display: none;
    }
  }

  @media all and (max-width: 600px) {
    width: calc(100% - 80px) !important;
  }

  @media all and (max-width: 500px) {
    width: calc(100% - 60px) !important;
    .ant-modal-content {
      .ant-modal-body {
        .content {
          .offer-title {
            font-size: 20px;
          }
          .offer-description {
          }
        }
      }
      .ant-modal-close {
        right: -24px;
        top: -4px;
      }
    }
  }
}