@import "./assets/styles/antd-styles-overrides";
@import "./assets/styles/mainVariables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  //user-select: none;
  > iframe {
    display: none;
  }
}


.modal-open {
  .headerWrapper {
    z-index: 100;
  }
}


button {
  cursor: pointer;
  border: none;
  padding: 0;
  user-focus: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
  }
}

img {
  user-focus: none;
  user-select: none;
}

p {
  margin-bottom: 0;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10101;
  overflow: hidden;

  .ant-modal {
    width: 100% !important;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;

    .ant-modal-header, .ant-modal-footer, .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {

      position: static;
      border-radius: 8px;
      background-color: transparent;
    }

    .slider-modal-content {
      border-radius: 8px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;

      .dialog-closing-btn {
        top: 80px;
        width: 40px;
        height: 40px;
        opacity: 0;

        svg {
          width: 100%;
          height: 100%;

          polygon {
            fill: white;
          }
        }

        &.show {
          transition: opacity 0.2s;
          opacity: 1;
        }
      }
    }

  }
}

@media screen and (max-width: 700px) {
  .image-wrapper {
    .main-slider-items {
      .main-slider-inner-part {
        .projects-wrapper {
          position: unset !important;
          top: unset !important;
          left: unset !important;
        }
      }
    }
  }
}

[class*="ground-pane"] {
  filter: grayscale(1);
}

.ymaps-2-1-79-map {
  width: 100vw;
  height: 100vh;
}

//yandex-map__object-marker yandex-map__object-marker_with_name j-yandex-map__marker is-active
