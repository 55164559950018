@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.custom-page-wrapper {
  padding: $headerHeight 0;
  overflow: hidden;
  background-color: #f9f9f9;

  .header-content {
    width: $mainContent;
    padding-top: 27.8%;
    position: relative;
    margin: auto;
    background-color: #232D35;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .header-title {
      width: 100%;
      color: white;
      position: absolute;
      z-index: 1;
      bottom: 20px;
      padding-left: $mainPadding;
      font-size: 50px;
      font-family: Bold;
      letter-spacing: 2px;
    }
  }

  .custom-page-content {
    //font-family: Regular;
    font-size: 16px!important;
    font-weight: 500;
    line-height: 28px;

    display: flex;
    flex-direction: column;
    width: $mainContent;
    background: white;
    margin: 80px auto 0;
    padding: 80px $mainPadding;
    *, {
      //font-family: Regular!important;
      font-size: 16px!important;
      font-weight: 500;
      line-height: 28px;
    }
    img {
      width: 100%;
    }

    .custom-page-content-extension {
      font-size: 13px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 500;
      margin-top: 30px;
      //line-height: 28px;

      span {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        color: #1264A3;

        svg {
          transition: transform 0.2s;

          path {
            fill: #1264A3
          }

        }

        &.rotated {
          svg {
            transform: rotateX(180deg);
          }
        }
      }
    }

    .extensions-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 22px;

      .extension-item {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        cursor: pointer;

        img {
          width: 144px;
          height: 96px;
        }

        span {
          font-family: Helvetica;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          padding-left: 12px;
        }
      }

    }
  }


  @media screen and (max-width: 1400px) {
    .custom-page-content {
      .left-part {
        width: 55%;
        padding: 40px;
      }

      .right-part {
        padding: 30px 0 0;

        .sub-title {
          font-size: 22px;
          font-family: Medium;
          margin-bottom: 20px;
        }

        .description {
          line-height: 24px;
          font-size: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .custom-page-content {
      .right-part {
        .sub-title {
          font-size: 20px;
          font-family: Medium;
          margin-bottom: 20px;
        }

        .description {
          line-height: 24px;
          font-size: 15px;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .header-content {
      .header-title {
        font-size: 40px;
      }
    }

    .custom-page-content {
      margin-top: 0;
      flex-direction: column;

      .left-part {
        width: 100%;
      }

      .right-part {
        width: 100%;
        padding: 40px 0 0;
      }
    }

    .custom-page-products-wrapper {
      margin: 0 -10px 0 0;

      .arrows {
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
    .header-content {
      .header-title {
        font-size: 28px;
        line-height: 28px;
      }
    }
    .custom-page-content {
      padding: 30px 20px;
      .extensions-wrapper{
        .extension-item {
          img {
            width: 108px;
            height: 72px;
          }
        }
      }
    }
  }
}
